module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Dashboard","short_name":"dxDash","description":"Personal homepage","start_url":"/","background_color":"#0a68f0","theme_color":"#0a68f0","display":"standalone","icon":"src/images/pwa-icon.png","icons":[{"src":"/favicons/pwa-icon-192x192.png","sizes":"192x192","type":"image/png"},{"src":"/favicons/pwa-icon-512x512.png","sizes":"512x512","type":"image/png"}],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"563bd57ffebdcd3ac18fb574729ce8ea"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
